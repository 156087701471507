import Grid from '@mui/material/Grid';
import dynamic from 'next/dynamic';

const HeaderLogo = dynamic(() => import("./HeaderLogo"));
const HeaderSearch = dynamic(() => import("./HeaderSearch"));
const HeaderTools = dynamic(() => import("./HeaderTools"));

const HeaderBar2 = () => {
  return (
    <>
      <Grid columnSpacing={12} container className='mt-2'>
        <Grid item lg={3} xl={2}>
            <HeaderLogo/>
        </Grid>
        <Grid className="d-flex align-items-end" item lg={5} xl={6}>
            <HeaderSearch/>
        </Grid>
        <Grid item lg={4} xl={4}>
            <HeaderTools/>
        </Grid>
      </Grid>
    </>
  )
}

export default HeaderBar2